.Notifications {
    .hiddenPanel {
        width: 400px;
        min-height: 100vh;
        border-left: 1px solid;
        display: flex;
        flex-direction: column;
        padding: 8px 8px;
    }
    
    .header {
        display: inline-flex;
        padding: 8px;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
    
        & .title {
            flex-grow: 0.9;
            flex-direction: column;
        }
    }
    
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
