.SidebarMain {
    .bar {
        border-right: 1px solid;
        display: flex;
        flex-direction: row;
        padding: 8px 8px;
        min-height: 100vh;
        overflow: hidden;
        width: 64px;
        transition: width 150ms ease-in-out !important;
    
        &:hover {
            width: 210px;
        }
    }
    
    .navigation {
        & > * {
            margin-block: 4px;
        }
    }
}
