body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body {
    max-width: 100vw;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

.noselect {
    user-select: none;
}

.content {
    display: flex;
    position: relative;
    left: 0;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
